import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Native and mobile apps cannot store secrets in a secure way. Consequently, it’s not recommended to use the standard
authorization code flow, since it requires a client secret when exchanging the authorization code for an access token
on the token endpoint. By selecting the “Native application” option on the IBM Video Streaming dashboard, PKCE
(`}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7636"
      }}>{`https://tools.ietf.org/html/rfc7636`}</a>{`) protocol can be forced to secure the authorization flow. PKCE is a technique for public
clients to mitigate the threat of having the authorization code intercepted. Clients need to create a secret, then use
that secret again when exchanging the authorization code for an access token. This way if the code is intercepted,
by a malicious application it won’t be able to use it because the token request relies on the initial secret.`}</p>
    <h3>{`Generate a code verifier and code challenge`}</h3>
    <p>{`Apps must generate a unique code verifier for every authorization request. This value must be transformed to a
`}<inlineCode parentName="p">{`code_challenge`}</inlineCode>{`, which is sent to the authorization server to obtain the authorization code.
A `}<inlineCode parentName="p">{`code_verifier`}</inlineCode>{` is a high-entropy cryptographic random string using the unreserved characters
`}<inlineCode parentName="p">{`[A-Z]`}</inlineCode>{` / `}<inlineCode parentName="p">{`[a-z]`}</inlineCode>{` / `}<inlineCode parentName="p">{`[0-9]`}</inlineCode>{` / `}<inlineCode parentName="p">{`-`}</inlineCode>{` / `}<inlineCode parentName="p">{`.`}</inlineCode>{` / `}<inlineCode parentName="p">{`_`}</inlineCode>{` / `}<inlineCode parentName="p">{`~`}</inlineCode>{`, with a minimum length of 43 characters and a maximum length of 128 characters.
The code verifier should have enough entropy to make it impractical to guess the value.`}</p>
    <Title mdxType="Title">Supported methods for generating a code challenge</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`METHOD`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`plain`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The code challenge is the same value as the code verifier generated above. `}<inlineCode parentName="td">{`code_challenge`}</inlineCode>{` = `}<inlineCode parentName="td">{`code_verifier`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`S256`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The code challenge is the Base64URL (without padding) encoded SHA256 hash of the code verifier. `}<inlineCode parentName="td">{`code_challenge`}</inlineCode>{` = `}<inlineCode parentName="td">{`BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Send a request to the auth server`}</h3>
    <p>{`To obtain user authorization, send a request to the authorization server at `}<a parentName="p" {...{
        "href": "https://authentication.video.ibm.com/authorize"
      }}>{`https://authentication.video.ibm.com/authorize`}</a>{`. This endpoint handles active session lookup, authenticates the user, and obtains user consent.
The authorization server supports the following additional query string parameters for installed applications:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code_challenge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies an encoded `}<inlineCode parentName="td">{`code_verifier`}</inlineCode>{` that will be used as a server-side challenge during authorization code exchange`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code_challenge_method`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defaults to `}<inlineCode parentName="td">{`plain`}</inlineCode>{`. Must be used with `}<inlineCode parentName="td">{`code_challenge`}</inlineCode>{`. Supported values: `}<inlineCode parentName="td">{`plain`}</inlineCode>{`, `}<inlineCode parentName="td">{`S256`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Exchange authorization code for refresh and access tokens`}</h3>
    <p>{`To exchange an authorization code for an access token, call the token endpoint (`}<a parentName="p" {...{
        "href": "https://video.ibm.com/oauth2/token"
      }}>{`https://video.ibm.com/oauth2/token`}</a>{`) and set the following parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`grant_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MUST be `}<inlineCode parentName="td">{`authorization_code`}</inlineCode>{` in this case.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-character long string, provided by IBM Video Streaming`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The authorization code received from the authorization endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code_verifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code verifier that has been created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`redirect_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The redirect URI used by the authorization server to return the authorization response`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <p>{`The following is an example with the authorization code flow using PKCE.`}</p>
    <p>{`1 - The client opens a browser with the authorization endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://authentication.video.ibm.com/authorize
?response_type=code
&client_id=AAAAAAAAAABBBBBBBBBBCCCCCCCCCCDDDDDDDDDD
&redirect_uri=https://example.com/get_auth_code
&scope=broadcaster
&state=XYZ
&code_challenge=bWl6dQ
&code_challenge_method=S256
`}</code></pre>
    <p>{`2 - The user enters his/her credentials and presses the Allow button. The browser is redirected to the following URL:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://example.com/get_access_token?code=19d8dbb3ebac55f110c3b526e38bcfdfbf46d659&state=XYZ
`}</code></pre>
    <p>{`3 - The page handler at `}<a parentName="p" {...{
        "href": "http://example.com/get_access_token"
      }}>{`http://example.com/get_access_token`}</a>{` retrieves the Access Token using the Token Endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /oauth2/token HTTP/1.1
Host: video.ibm.com
Content-Type: application/x-www-form-urlencoded

grant_type=authorization_code&client_id=AAAAAAAAAABBBBBBBBBBCCCCCCCCCCDDDDDDDDDD&code=19d8dbb3ebac55f110c3b526e38bcfdfbf46d659&redirect_uri=http://example.com/get_access_token&code_verifier=asdf
`}</code></pre>
    <p>{`4 - The response of the Token Endpoint contains the access token:`}</p>
    <pre><code parentName="pre" {...{}}>{`HTTP/1.1 200 OK
Cache-Control: no-store
Content-Type:application/json; charset=UTF-8

{"access_token":"ab345cdef123ef1267890abcdef04567890abcd1","refresh_token":"cb345cdef123ef1267890abcdef04567890abcd1","token_type":"bearer", "expires_in":86400}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      